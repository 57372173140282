import * as React from "react";
import { I18nextProvider } from "react-i18next";
import { ScreenSizeContextProvider } from "./src/context/ScreenSizeContext";
import { SocialPopup } from "./src/components/popups";
import "./global.css";
import i18n from "./src/i18n";

export const wrapRootElement = ({ element }) => {
  return (
    <ScreenSizeContextProvider>
      <I18nextProvider i18n={i18n}>
        <>
          <SocialPopup />
        </>
        <>{element}</>
      </I18nextProvider>
    </ScreenSizeContextProvider>
  );
};
